import { StyleSheet, Text, View, StatusBar, useWindowDimensions } from "react-native";
import HomeScreen from "screens/HomeScreen";
import {
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_800ExtraBold,
} from "@expo-google-fonts/poppins";
import { Baloo_Regular400 } from "@expo-google-fonts/baloo";
import { loadAsync } from "expo-font";
import { useCallback, useEffect, useState } from "react";
import { locale } from "expo-localization";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDefault from "./i18n.json";
import translationZH from "./src/i18n/i18n_zh.json";
import translationEN from "./src/i18n/i18n_en.json";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Animated, { BounceIn } from "react-native-reanimated";

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  default: {
    translation: translationEN,
  },
};

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  const Fonts = async () => {
    await loadAsync({
      Poppins_300Light,
      Poppins_400Regular,
      Poppins_500Medium,
      Poppins_600SemiBold,
      Poppins_700Bold,
      Poppins_800ExtraBold,
      Baloo_Regular400,
    });
  };

  const getDeviceOperation = async () => {
    // 判断不同端
    const plat = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  };
  const setI18n = async () => {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next) // bind react-i18next to the instance
      .init({
        compatibilityJSON: "v3",
        resources,
        lng: locale, // get locale by expo-localization
        fallbackLng: "default",
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
  };

  useEffect(() => {
    const prepare = async () => {
      try {
        await Fonts(); // font must come first!
        await getDeviceOperation();
        await setI18n();
        // setupLocale();
      } catch (e) {
        //handle errors
        console.log(e);
      } finally {
        setAppIsReady(true);
      }
    };

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    console.log("performed layout");
    if (appIsReady) {
      console.log("app is ready");
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      // if (width > 1200) {
      //   dispatch(setDeviceType(DeviceTpye.PC));
      // } else {
      //   dispatch(setDeviceType(DeviceTpye.MOBILE));
      // }
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }
  return (
    <Animated.View style={styles.container} onLayout={onLayoutRootView} entering={BounceIn}>
      <StatusBar barStyle={"dark-content"} hidden />
      <HomeScreen />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
