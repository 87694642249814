import React, { Component } from 'react';
import { View, StyleSheet, Animated, Image, ImageProps } from 'react-native';

const styles = StyleSheet.create({
  imageOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  globeImg: {
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    position: "absolute",
  }
});

interface ProgressiveImageProps extends ImageProps {
  thumbnailSource: ImageProps['source'];
}

interface ProgressiveImageState {
  thumbnailAnimated: Animated.Value;
  imageAnimated: Animated.Value;
}

class ProgressiveImage extends Component<ProgressiveImageProps, ProgressiveImageState> {
  state = {
    thumbnailAnimated: new Animated.Value(0),
    imageAnimated: new Animated.Value(0),
  };

  handleThumbnailLoad = () => {
    Animated.timing(this.state.thumbnailAnimated, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };

  onImageLoad = () => {
    Animated.timing(this.state.imageAnimated, {
      toValue: 1,
      useNativeDriver: false,
    }).start(() => {
      // Animation finished
      Animated.timing(this.state.thumbnailAnimated, {
        toValue: 0,
        useNativeDriver: false,
      }).start();
    });
  };

  render() {
    const {
      thumbnailSource,
      source,
      style,
      ...props
    } = this.props;

    return (
      <View style={style}>
        <Animated.Image
          {...props}
          source={thumbnailSource}
          style={[styles.globeImg, { opacity: this.state.thumbnailAnimated }]}
          onLoad={this.handleThumbnailLoad}
          blurRadius={1}
        />
        <Animated.Image
          {...props}
          source={source}
          style={[styles.imageOverlay, { opacity: this.state.imageAnimated }, styles.globeImg]}
          onLoad={this.onImageLoad}
        />
      </View>
    );
  }
}

export default ProgressiveImage;
