import {
  Pressable,
  StyleSheet,
  Image,
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  Linking,
  Platform,
} from "react-native";
import React, { useEffect } from "react";
import AndroidPng from "assets/android_logo.png";
import NameSvg from "assets/name.png";
import NameMinSvg from "assets/name-min.png";
import QRPng from "assets/qrCode2.png";
import QRPngMin from "assets/qrCode2-min.png";
import { DeviceTpye } from "constant/DeviceType";
import ScreenshotsScroll from "./ScreenshotsScroll";
import { useTranslation } from "react-i18next";
import { locale } from "expo-localization";
import ScreenshotsMobile from "../component/screenShots/ScreenshotsMobile";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import ProgressiveImage from "component/ProgressiveImage";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";

const Subjects = () => {
  const { height, width, scale, fontScale } = useWindowDimensions();
  const deviceType = width > 1000 ? DeviceTpye.PC : DeviceTpye.MOBILE;
  const { t } = useTranslation();
  const index = height > 1500 ? height / 1500 : 1;
  const smallIndex = width < 300 ? width / 350 : 1;
  const handleDownload = (platfrom: Platform["OS"]) => {
    console.log(platfrom);
    switch (platfrom) {
      case "android":
        Linking.openURL("https://pub-c4c8405eadc74aa7980eb900cfc9abaf.r2.dev/lolly.apk");
        break;
      case "ios":
        Linking.openURL("https://apps.apple.com/us/app/lolly/id1668632400");
        break;
      default:
        break;
    }
  };

  const handleTest = () => {
    Linking.openURL("https://testflight.apple.com/join/8qryD28k");
  };
  const sharedValue = useSharedValue(0.98);

  useEffect(() => {
    sharedValue.value = withRepeat(withTiming(1, { duration: 750 }), -1, true);
  }, []);

  const rStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: sharedValue.value,
        },
      ],
    };
  }, []);

  return (
    <View
      style={{
        marginTop: deviceType == DeviceTpye.MOBILE ? 0 : 100,
        marginBottom: deviceType == DeviceTpye.MOBILE ? 0 : 180,
        width: deviceType == DeviceTpye.MOBILE ? width : width / 2.5,
        justifyContent: "space-between",
      }}
    >
      <View style={styles.container}>
        <View style={[styles.titleContainer, deviceType == DeviceTpye.MOBILE ? { marginTop: "2%" } : {}]}>
          <ProgressiveImage thumbnailSource={NameMinSvg} source={NameSvg} resizeMode="contain" style={styles.logo} />
        </View>
        <View>
          <Text
            numberOfLines={2}
            adjustsFontSizeToFit
            style={[
              styles.subtitleText,
              {
                fontSize: deviceType == DeviceTpye.MOBILE ? 30 * index : 45,
                // lineHeight: deviceType == DeviceTpye.MOBILE ? 40 * index : 60,
              },
            ]}
          >
            {t("slogan-text")}
          </Text>
        </View>
        <Animated.View style={[rStyle, { height: "10%", minHeight: 50, marginTop: "3%" }]}>
          <TouchableOpacity
            activeOpacity={0.4}
            onPress={() => handleDownload("android")}
            style={[
              styles.downloadButton,
              deviceType == DeviceTpye.MOBILE
                ? { width: 300 * smallIndex }
                : { width: 330 * index, height: 70 * index },
            ]}
          >
            <View style={styles.androidImage}>
              <FontAwesome name="android" size={26} color="white" />
            </View>
            <Text style={[styles.downloadText, { fontSize: 20 * index * smallIndex }]}>
              {t("Download for android")}
            </Text>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={[rStyle, { height: "10%", minHeight: 50, marginTop: "3%" }]}>
          <TouchableOpacity
            activeOpacity={0.4}
            onPress={() => handleDownload("ios")}
            style={[
              styles.downloadButton,
              deviceType == DeviceTpye.MOBILE
                ? { width: 300 * smallIndex }
                : { width: 330 * index, height: 70 * index },
            ]}
          >
            <View style={styles.newButton}>
              <Text style={styles.newText}>{t("new-text")}</Text>
            </View>
            <View style={styles.appleImage}>
              <FontAwesome5 name="apple" size={26} color="white" />
            </View>
            <Text style={[styles.downloadText, { fontSize: 20 * index * smallIndex }]}>{t("Download for iOS")}</Text>
          </TouchableOpacity>
        </Animated.View>
        {/* <View style={{ flexDirection: "row", marginTop: "3%" }}>
          <Text numberOfLines={2} style={[styles.commingSoonText, { fontSize: 18 * index }]}>
            {width > 300 ? t("iOS external test") : t("iOS test")}
          </Text>
          <View style={{ height: "100%", aspectRatio: 1, marginLeft: "10%", minHeight: 50 }}>
            <TouchableOpacity onPress={handleTest} style={styles.qrImage}>
              <ProgressiveImage thumbnailSource={QRPngMin} source={QRPng} resizeMode="contain" style={styles.qrImage} />
            </TouchableOpacity>
          </View>
        </View> */}
        <View style={{}}>
          <Text style={[styles.additionText, { fontSize: 13 * index }]}>{t("Thanks for waiting")}</Text>
        </View>
      </View>
      {deviceType == DeviceTpye.MOBILE && <ScreenshotsMobile />}
    </View>
  );
};

export default Subjects;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "center",
    justifyContent: "space-around",
    // backgroundColor:"red"
  },
  titleContainer: {
    flexDirection: "row",
    height: "10%",
    minHeight: 60,
    width: "100%",
  },
  logo: {
    marginLeft: "9%",
    height: "100%",
    minHeight: 60,
    aspectRatio: 190 / 71,
    resizeMode: "contain",
    justifyContent: "flex-start",
  },
  subtitleText: {
    fontFamily: "Poppins_700Bold",
    color: "black",
    marginLeft: "10%",
  },
  commingSoonText: {
    marginLeft: "10%",

    fontFamily: "Poppins_800ExtraBold",
    color: "#FF4A90",
    //fontSize: 20,
  },
  additionText: {
    marginLeft: "10%",

    fontFamily: "Poppins_300Light",
    color: "#000000",
    //fontSize: 13,
  },
  downloadButton: {
    marginLeft: "10%",
    width: 300,
    height: "100%",
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#FF4A90",
    borderWidth: 2,
    borderColor: "#FF4A90",

    shadowColor: "#ACBAEA",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 20.27,
    elevation: 10,
  },
  downloadText: {
    fontFamily: "Poppins_600SemiBold",
    color: "white",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",

    //fontSize: 20,
  },
  androidImage: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    resizeMode: "cover",
    // aspectRatio: 1,

    // height: "100%",
  },
  appleImage: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    resizeMode: "cover",
    // aspectRatio: 1,
    // height: "100%",
  },
  newButton: {
    position: "absolute",
    right: -30,
    top: -10,
    width: 85,
    height: 30,
    backgroundColor: "white",
    borderRadius: 40,
    borderWidth:1.5,
    borderColor:"#FF4A90",
    alignItems: "center",
    justifyContent: "center",
    transform: [{ rotate: "15deg" }],

    shadowColor: "#ACBAEA",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8.27,
    elevation: 6,
  },
  newText: {
    fontFamily: "Poppins_600SemiBold",
    color: "#FF4A90",
    fontSize:15,

  },
  qrImage: {
    flex: 1,
    aspectRatio: 1,
    // height: "100%",
    // minheight: 100,
    resizeMode: "cover",
    // marginLeft: "10%",
  },
});
