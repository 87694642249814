import { Pressable, StyleSheet, Image, View, Text, ScrollView, useWindowDimensions } from "react-native";
import React from "react";
import screenshots from "assets/screenshot/screenshots.png";
import globe from "assets/screenshot/globe.png";
import globeMin from "assets/screenshot/globe-min.png";
import screenshotsMin from "assets/screenshot/screenshots-min.png";
import ProgressiveImage from "component/ProgressiveImage";

const ScreenshotsMobile = () => {
  const { height, width, scale, fontScale } = useWindowDimensions();

  return (
    <View style={{ width: width, height: height / 2.1, overflow: "hidden" }}>
      <ProgressiveImage
        thumbnailSource={globeMin}
        source={globe}
        resizeMode="contain"
        style={styles.globeImg}
      />
      <ProgressiveImage
        thumbnailSource={screenshotsMin}
        source={screenshots}
        resizeMode="contain"
        style={styles.screenshotImg}
      />
    </View>
  );
};

export default ScreenshotsMobile;

const styles = StyleSheet.create({
  container: {},
  screenshotImg: {
    height: "100%",
    resizeMode: "contain",
    left: "-3.3%",
    transform: [
      {
        scale: 1.25,
      },
    ],
  },
  globeImg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    transform: [
      {
        scale: 1,
      },
    ],
    bottom: "-50%",
    resizeMode: "contain",
    overflow: "hidden",
  },
});
