import { Pressable, StyleSheet, Image, View, Text, ScrollView, useWindowDimensions } from "react-native";
import React from "react";
import screenshots from "assets/screenshot/screenshots.png";
import globe from "assets/screenshot/globe.png";
import globeMin from "assets/screenshot/globe-min.png";
import ProgressiveImage from "component/ProgressiveImage";
import screenshotsMin from "assets/screenshot/screenshots-min.png";

const ScreenshotsPC = () => {
  const { height, width, scale, fontScale } = useWindowDimensions();
  const [throttle, setThrottle] = React.useState(16);

  return (
    <View style={{ width: width / 1.67, height: height }}>
      <ProgressiveImage
        thumbnailSource={globeMin}
        source={globe}
        resizeMode="contain"
        style={styles.globeImg}
      />
      <ProgressiveImage
        thumbnailSource={screenshotsMin}
        source={screenshots}
        resizeMode="contain"
        style={styles.progressiveContainer}
      />
    </View>
  );
};

export default ScreenshotsPC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screenshotImg: {
    height: "100%",
    resizeMode: "contain",
  },
  globeImg: {
    position: "absolute",
    height: "100%",
    width: "150%",
    left: "-65%",
    resizeMode: "contain",
    overflow: "hidden",
  },
  progressiveContainer: {
    height: "100%",
    width: "100%",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    position: "absolute"
  }
});
