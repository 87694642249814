import { Pressable, StyleSheet, Text, View, useWindowDimensions } from "react-native";
import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import screenshot1 from "assets/screenshot/Screen-Recording-2023-02-13-at-8.gif";
import screenshot2 from "assets/screenshot/Screen-Recording-2023-02-13-at-9.gif";
import screenshot3 from "assets/screenshot/Screen-Recording-2023-02-14-at-6.gif";
import Screenshots from "./Screenshots";
import Subjects from "./Subjects";
import { DeviceTpye } from "constant/DeviceType";
import ScreenshotsPC from "../component/screenShots/ScreenshotsPC";

const HomeScreen = () => {
  // const deviceType = getDeviceType();
  const { height, width, scale, fontScale } = useWindowDimensions();
  const deviceType = width > 1000 ? DeviceTpye.PC : DeviceTpye.MOBILE;

  return (
    <LinearGradient
      colors={["#EEF2FF", "#FFFFFF"]}
      start={{ x: 0.1, y: 0 }}
      end={{ x: 0.2, y: 0.5 }}
      locations={[0.2, 0, 0]}
      style={styles.container}
      useAngle={true}
      angle={90}
    >
      {deviceType == DeviceTpye.PC && <ScreenshotsPC />}
      <Subjects />
    </LinearGradient>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  background: {},
});
